import React from 'react';

// styled
import Section from '../styled/Section';
import Wrapper from '../styled/Wrapper';
import Grid from '../styled/Grid';

// assets
import adobe from '../../assets/adobe.png';
import ibm from '../../assets/ibm.png';
import lilly from '../../assets/lilly.png';
import spotify from '../../assets/spotify.png';
import capitalOne from '../../assets/capital-one.png';
import walmartLabs from '../../assets/walmart-labs.png';
import visa from '../../assets/visa.png';

const SelectedClients = () => (
  <Section noPaddingTop>
    <Wrapper>
      <Grid>
        <div>
          <img src={adobe} />
        </div>
        <div>
          <img src={ibm} />
        </div>
        <div>
          <img src={lilly} />
        </div>
        <div>
          <img src={spotify} />
        </div>
        <div>
          <img src={capitalOne} />
        </div>
        <div>
          <img src={walmartLabs} />
        </div>
        <div>
          <img src={visa} />
        </div>
      </Grid>
      <div style={{ textAlign: 'center', fontSize: 14 }}>
        <p>and many more!</p>
      </div>
    </Wrapper>
  </Section>
);

export default SelectedClients;
