import React from 'react';

// components
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';

// styled
import Superscript from '../components/styled/Superscript';
import Description from '../components/styled/Description';
import Section from '../components/styled/Section';
import Container from '../components/styled/Container';
import Wrapper from '../components/styled/Wrapper';
import Button from '../components/styled/Button';

// sections
import SelectedClients from '../components/sections/SelectedClients';

class Sponsorship extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Sponsorship" />
        <Nav selected="/sponsorship" />
        <Header />
        <Section noPaddingTop>
          <Wrapper>
            <Superscript small center>
              Become a sponsor
            </Superscript>
              <p>
                Last year's event attracted a very passionate and excited group of developers, mostly from medium- and large-sized companies.  
                They are typically forward-thinking people who care a lot about quality and good development process.
              </p>
              <p>
                Last year’s attendees were software developers and IT managers from companies like:
              </p>
          </Wrapper>
        </Section>

        <SelectedClients />

        <Section noPaddingTop center>
          <Wrapper>
            <Button href="https://docs.google.com/forms/d/1wHVgCBWT17F2w-7BTxI5Wf3F0bilNXlls-2cTLSwNWI/viewform?edit_requested=true">Learn more</Button>
          </Wrapper>
        </Section>

        <Section noPaddingTop center>
          <Wrapper>
            Or email assertjs@okgrow.com.
          </Wrapper>
        </Section>

        <Footer />
      </Container>
    );
  }
}

export default Sponsorship;
