import styled from 'styled-components';

const Description = styled.p`
  width: 60%;
  margin: 0 auto;
  font-family: Electrolize;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  @media (max-width: 680px) {
    width: 90%;
  }
`;

export default Description;
