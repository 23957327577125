import styled from 'styled-components';

const Grid = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 24px;

  div {
    display: flex;
    align-items: center;
    width: 25%;
    height: 150px;
    padding: 24px;
    text-align: center;
    justify-content: center;
    ${props => props.large && `width: 50%; height: 250px; a { height: 202px }`}
    ${props =>
      props.medium && `width: 32%; height: 150px; a { height: 102px; }`}
    ${props => props.small && `width: 20%; height: 100px; a { height: 52px;}`}
    a {
      line-height: 0;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    img {
      margin-bottom: 0;
      max-width:100%;
      max-height:100%;
    }
    @media (max-width: 680px) {
      width: 50%;
    }
  }
`;

export default Grid;
